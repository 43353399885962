<template>
  <div class="loader-extra">
    <NuxtImg
      class="loader-extra-breathe"
      src="/img/extracadabra_animated_vf.gif"
      loading="lazy"
    />
    <span class="loader-extra-text"
      >{{ $t("COMPONENTS.COMMON.LOADER_EXTRA.@LOADING") }}<span class="dot-pulse"></span
    ></span>
  </div>
</template>
<style lang="scss" scoped>
.loader-extra {
  margin: 30px auto;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 15px;
  &-breathe {
    animation: extraBreathin 1s ease-out infinite normal;
    -webkit-font-smoothing: antialiased;
    width: 50px;
    height: 50px;
  }
  &-text {
    color: #000;
    font-weight: 800;
    color: $primary-color;
  }
  .dot-pulse {
    position: relative;
    display: inline-block;
    margin-left: 20px;
    left: -9999px;
    width: 2px;
    height: 2px;
    border-radius: 2px;
    background-color: $primary-color;
    color: $primary-color;
    box-shadow: 9999px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
  }
  .dot-pulse::before,
  .dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 2px;
    height: 2px;
    border-radius: 2px;
    background-color: $primary-color;
    color: $primary-color;
  }
  .dot-pulse::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
  }
  .dot-pulse::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
  }

  @keyframes dot-pulse-before {
    0% {
      box-shadow: 9984px 0 0 -5px;
    }
    30% {
      box-shadow: 9984px 0 0 2px;
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -5px;
    }
  }
  @keyframes dot-pulse {
    0% {
      box-shadow: 9999px 0 0 -5px;
    }
    30% {
      box-shadow: 9999px 0 0 2px;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -5px;
    }
  }
  @keyframes dot-pulse-after {
    0% {
      box-shadow: 10014px 0 0 -5px;
    }
    30% {
      box-shadow: 10014px 0 0 2px;
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -5px;
    }
  }
}

@keyframes extraBreathin {
  0% {
    transform: scale(0.9);
  }

  25% {
    transform: scale(1);
  }

  60% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    transform: scale(0.9);
  }

  25% {
    transform: scale(1);
  }

  60% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(0.9);
  }
}
</style>
