<script setup lang="ts">
import type { APIParamsPagination } from "~/models/Api";
import { ARTICLES_BY_TAGS_PAGINATION, ARTICLES_PER_PAGE, routes } from "~/constants";
const appConfig = useAppConfig();

const { $api } = useNuxtApp();
const { t } = useI18n();

const selectedTags = ref<string[]>([]);
const articlesByTagPagination = ref<APIParamsPagination>(ARTICLES_BY_TAGS_PAGINATION);

const { data: articles, pending } = await $api.articles.getByTagsSlug(
  {
    key: "homepageArticles",
    slugs: selectedTags,
    pagination: articlesByTagPagination,
  },
  { watch: [selectedTags, articlesByTagPagination] },
);

const { data: tags } = await $api.tags.list();
const { data: latestArticles } = await $api.latestArticles.list();
const { data: sliderArticles } = await $api.sliderArticles.list();

const menuItems = computed(() => {
  const items =
    tags?.value?.data.map(({ title: label, slug: value }) => ({
      label,
      value,
    })) ?? [];
  return [{ label: "Tous les articles", value: undefined }, ...items];
});

useSeoMeta({
  title: t("BLOG.INDEX.SEO.@TITLE"),
  ogTitle: t("BLOG.INDEX.SEO.@TITLE"),
  description: t("BLOG.INDEX.SEO.@DESCRIPTION"),
  ogDescription: t("BLOG.INDEX.SEO.@DESCRIPTION"),
  ogImage:
    "https://static-cadabra.s3.eu-west-3.amazonaws.com/large_Presentation_Extracadabra_854bbec0c5.png",
});

const showLoadMoreButton = computed(
  () => articles.value?.data?.length !== articles.value?.meta?.pagination?.total,
);

const seeMoreArticle = () => {
  const limit = articlesByTagPagination.value.limit ?? ARTICLES_BY_TAGS_PAGINATION.limit;

  articlesByTagPagination.value = {
    start: articlesByTagPagination.value.start ?? ARTICLES_BY_TAGS_PAGINATION.start,
    limit: limit + ARTICLES_PER_PAGE,
  };
};

const updateArticles = (categoryValue: string) => {
  articlesByTagPagination.value = ARTICLES_BY_TAGS_PAGINATION;
  selectedTags.value = [categoryValue] ?? [];
};
</script>

<template>
  <NuxtLayout>
    <template #content-header>
      <CarouselBanner
        class="blog-container-slider"
        :slider-articles="sliderArticles?.data.articles ?? []"
      />
    </template>
    <section class="row">
      <h2 class="title">{{ $t("BLOG.INDEX.LATEST_ARTICLES.@TITLE") }}</h2>
      <section class="row">
        <CardArticle
          v-for="article in latestArticles?.data.articles ?? []"
          :key="article.id"
          :article="article"
          :read-more="{
            label: $t('COMPONENTS.CARDS.CARD_ARTICLE.@READ_MORE'),
            url: `${routes.blogHomeUrl}/articles/${article.slug}`,
          }"
        />
      </section>
      <section class="row">
        <h2 class="title small">{{ $t("BLOG.INDEX.@ARTICLES_BY_THEMES") }}</h2>
        <section class="col-xs-12 col-md-9">
          <TabSlider :list-items="menuItems" @update-list="updateArticles" />
          <div class="horizontal-container-cards">
            <ol>
              <CardArticleHorizontal
                v-for="article in articles?.data"
                :key="article.id"
                :article="article"
              />
            </ol>
            <SeeMoreButton
              v-if="!pending"
              :has-other-articles="showLoadMoreButton"
              :label="$t('COMPONENTS.SEE_MORE_BUTTON.@LABEL')"
              @click="() => seeMoreArticle()"
            />
            <LoaderExtra v-if="pending" />
          </div>
        </section>
        <section class="download-app-container col-xs-12 col-md-3">
          <DownloadApp
            :title="$t('COMPONENTS.DOWNLOAD_APP.CANDIDATES.@TITLE_1')"
            :sub-title="$t('COMPONENTS.DOWNLOAD_APP.@SUB_TITLE')"
            color="#ff2c55"
            img-link="/img/picto-app-candidat.png"
            :playstore="appConfig.applications.playStore.candidates"
            :applestore="appConfig.applications.appleStore.candidates"
            type="candidates"
            source-origin="home_blog"
          />
          <DownloadApp
            :title="$t('COMPONENTS.DOWNLOAD_APP.ETAB.@TITLE_1')"
            :sub-title="$t('COMPONENTS.DOWNLOAD_APP.@SUB_TITLE')"
            color="#188DA7"
            img-link="/img/picto-app-recruteur.png"
            :playstore="appConfig.applications.playStore.etab"
            :applestore="appConfig.applications.appleStore.etab"
            type="etab"
            source-origin="home_blog"
          />
        </section>
      </section>
    </section>
  </NuxtLayout>
</template>

<style lang="scss" scoped>
.horizontal-container-cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.download-app-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: sticky;
  top: 60px;
  height: 970px;
  padding-bottom: 300px;
}
</style>
