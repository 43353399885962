<script setup lang="ts">
const { hasOtherArticles } = defineProps<{ hasOtherArticles: boolean; label: string }>();
const emit = defineEmits<{
  click: [];
}>();
</script>

<template>
  <div class="see-more-button-wrapper">
    <button class="see-more-button" :disabled="!hasOtherArticles" @click="emit('click')">
      <small>{{ label }}</small>
      <i role="presentation" class="extra-arrow-next" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
.see-more-button-wrapper {
  margin-bottom: $gutter * 4;
  display: flex;
  justify-content: center;
  .see-more-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gutter;
    &[disabled] {
      display: none;
    }
    small {
      font-weight: 800;
      text-transform: uppercase;
      color: $grey;
    }
    .extra-arrow-next {
      &:before {
        font-size: 22px;
        color: $grey;
      }
      transform: rotate(90deg);
    }
  }
}
</style>
