<template>
  <div class="tabs-container">
    <div class="tabs-wrapper">
      <nav
        ref="productNav"
        class="tabs-nav"
        @mousedown="handleMouseDown"
        @touchstart="handleTouchStart"
        @mousemove="handleMouseMove"
        @touchmove="handleTouchMove"
        @mouseup="handleMouseUp"
        @touchend="handleTouchEnd"
      >
        <ul ref="tabsNav" class="tabs-nav-content" role="tablist">
          <li v-for="(tab, index) in listItems" :key="index" role="presentation">
            <TabSliderItem
              :tab="tab"
              :aria-posinset="index + 1"
              :aria-label="tab.label"
              :aria-setsize="listItems.length + 1"
              :aria-selected="index === activeIndex"
              :is-active="index === activeIndex"
              role="tab"
              @click="handleLinkClick(index, tab.value)"
              @touchend="handleLinkClick(index, tab.value)"
            />
          </li>
        </ul>
      </nav>

      <button
        v-show="showNavigationPrev"
        class="navigation prev"
        type="button"
        :aria-label="$t('COMPONENTS.TAB_SLIDER.NAVIGATION.@PREV')"
        @click="goPrev"
      >
        <i role="presentation" class="extra-arrow-next prev" />
      </button>
      <button
        v-show="showNavigationRight"
        class="navigation next"
        type="button"
        :aria-label="$t('COMPONENTS.TAB_SLIDER.NAVIGATION.@NEXT')"
        @click="goNext"
      >
        <i role="presentation" class="extra-arrow-next" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
const { listItems } = defineProps<{
  listItems: Array<{ label: string; value?: string }>;
}>();

const productNav = ref<HTMLElement | null>(null);
const isDragging = ref<boolean>(false);
const startX = ref<number>(0);
const currentX = ref<number>(0);
const tabsNav = ref<HTMLElement | null>(null);
const activeIndex = ref<number>(0);
const scrollAmount = ref<number>(0);
const scrollStep = 150;
const showNavigationPrev = ref<boolean>(false);
const showNavigationRight = ref<boolean>(false);
const isTouchDevice = process.client
  ? "ontouchstart" in window || navigator.maxTouchPoints > 0
  : false;

const handleScroll = () => {
  if (productNav.value) {
    scrollAmount.value = productNav.value.scrollLeft;
    updateNavigationVisibility();
  }
};

const goPrev = () => {
  if (productNav.value) {
    const currentScroll = productNav.value.scrollLeft;
    const newScroll = currentScroll - scrollStep;
    productNav.value.scrollLeft = newScroll;
  }
};

const goNext = () => {
  if (productNav.value) {
    const currentScroll = productNav.value.scrollLeft;
    const newScroll = currentScroll + scrollStep;
    productNav.value.scrollLeft = newScroll;
  }
};

const scrollTabs = (deltaX: number) => {
  if (!isDragging.value || !productNav.value) {
    return;
  }
  const scrollAmount = productNav.value.scrollLeft - deltaX;
  productNav.value.scrollLeft = scrollAmount;
};

const handleMouseDown = (e: MouseEvent) => {
  if (!isTouchDevice) {
    e.preventDefault();
    isDragging.value = true;
    startX.value = e.clientX;
    currentX.value = e.clientX;
  }
};

const handleMouseMove = (e: MouseEvent) => {
  if (!isDragging.value) {
    return;
  }
  const deltaX = e.clientX - currentX.value;
  currentX.value = e.clientX;
  scrollTabs(deltaX);
};

const handleMouseUp = () => {
  isDragging.value = false;
};

const handleTouchMove = (e: TouchEvent) => {
  if (!isDragging.value) {
    return;
  }
  const deltaX = e.touches[0].clientX - currentX.value;
  currentX.value = e.touches[0].clientX;
  scrollTabs(deltaX);
};

const handleTouchStart = (e: TouchEvent) => {
  if (isTouchDevice) {
    e.preventDefault();
    isDragging.value = true;
    startX.value = e.touches[0].clientX;
    currentX.value = e.touches[0].clientX;
  }
};

const handleTouchEnd = () => {
  isDragging.value = false;
};

const updateNavigationVisibility = () => {
  if (productNav.value && tabsNav.value) {
    const containerWidth = productNav.value.clientWidth;
    const scrollAmount = productNav.value.scrollLeft;
    const isOverflowingLeft = scrollAmount > 0;
    const isOverflowingRight = scrollAmount + containerWidth < contentsWidth.value;
    showNavigationPrev.value = isOverflowingLeft;
    showNavigationRight.value = isOverflowingRight;
  }
};

const emit = defineEmits(["updateList"]);

const handleLinkClick = (index: number, value?: string) => {
  activeIndex.value = index;
  if (tabsNav.value && productNav.value) {
    const selectedElement = tabsNav.value.querySelectorAll<HTMLElement>(
      ".tabs-nav-content-item",
    )[index];
    const containerWidth = productNav.value.clientWidth;
    const elementWidth = selectedElement.offsetWidth;
    const elementOffset = selectedElement.offsetLeft;
    const scrollLeft = elementOffset - (containerWidth - elementWidth) / 2;
    productNav.value.scrollLeft = scrollLeft;
    emit("updateList", value);
  }
};

const contentsWidth = ref<number>(0);

onMounted(() => {
  if (productNav.value) {
    productNav.value.addEventListener("scroll", handleScroll, { passive: true });
    setTimeout(() => {
      updateNavigationVisibility();
    }, 100);
    contentsWidth.value = tabsNav.value ? tabsNav.value.scrollWidth : 0;
  }
});

onUpdated(() => {
  updateNavigationVisibility();
  contentsWidth.value = tabsNav.value ? tabsNav.value.scrollWidth : 0;
});

onUnmounted(() => {
  if (productNav.value) {
    productNav.value.removeEventListener("scroll", handleScroll);
  }
});
</script>
<style scoped lang="scss">
.tabs-container {
  width: 100%;
  overflow: hidden;
  padding: 10px 0;
  position: relative;
  .tabs-wrapper {
    position: relative;
    .tabs-nav {
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      font-size: 0;
      display: flex;
      align-items: center;
      &-content {
        transition: transform 0.2s ease-in-out;
        position: relative;
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 3px solid $grey-light;
        &-item {
          text-decoration: none;
          color: #7f868b;
          font-size: 1rem;
          font-weight: 500;
          display: table-cell;
          vertical-align: middle;
          padding: 8px 12px;
          line-height: 1.35;
          text-transform: uppercase;
          font-weight: 800;
          margin: 0;
          &[aria-selected="true"] {
            position: relative;
            color: $primary-color;
            &:after {
              content: "";
              height: 3px;
              width: 100%;
              background-color: $primary-color;
              position: absolute;
              bottom: -13px;
              left: 0;
              pointer-events: none;
            }
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .navigation {
      appearance: none;
      background: transparent;
      padding: 0;
      border: 0;
      outline: 0;
      cursor: pointer;
      position: absolute;
      top: -12px;
      bottom: 0;
      transition: opacity 0.3s;
      color: $grey-medium;
      font-size: 20px;
      background-color: $white;
      padding-left: 10px;
      &.next {
        right: 0;
      }
      &.prev {
        left: 0;
        transform: rotate(-180deg);
      }
    }
  }
}
</style>
