<script setup lang="ts">
import type { Article } from "../../models";
import { routes } from "~/constants";

const { isDesktop, isMobile } = useDevice();
const { article } = defineProps<{
  article: Article;
}>();
</script>

<template>
  <li class="card-article-horizontal-item">
    <NuxtLink :to="`${routes.blogHomeUrl}/articles/${article.slug}`">
      <div class="card-article-horizontal-item-left">
        <NuxtImg
          v-if="article.banner && isDesktop"
          class="only-md"
          :src="article.banner.formats.small.url"
          :alt="article.banner.alternativeText"
          loading="lazy"
          :title="article.banner.alternativeText"
        />
        <div v-if="isMobile" class="card-article-horizontal-item-left-infos">
          <ButtonTag
            v-for="tag in article.tags.slice(0, 1)"
            :key="tag.id"
            :href="`${routes.blogHomeUrl}/${tag.slug}/1`"
            :tag-value="tag.title"
            class="card-article-horizontal-item-left-infos--tag"
            type="red"
          />
          <span class="card-article-horizontal-item-left-infos--date">{{
            dayjs(article.publishedAt).format(DATE_FORMAT)
          }}</span>
        </div>
      </div>
      <div class="card-article-horizontal-item-right">
        <div class="card-article-horizontal-item-right-top">
          <span class="card-article-horizontal-item-right-top--date">{{
            dayjs(article.updatedAt).format(DATE_FORMAT)
          }}</span>
          <span class="card-article-horizontal-item-right-top--author">
            <i role="presentation" class="extra-logo" />
            <I18nT keypath="COMPONENTS.@AUTHORED_BY" tag="span" scope="global">
              <template #author>
                <span>{{ article.author }}</span>
              </template>
            </I18nT>
          </span>
          <ButtonTag
            v-for="tag in article.tags.slice(0, 3)"
            :key="tag.id"
            :href="`${routes.blogHomeUrl}/${tag.slug}/1`"
            :tag-value="tag.title"
            type="primary"
          />
        </div>
        <div class="card-article-horizontal-item-right-middle">
          <h2 v-if="isDesktop">{{ trimString(article.title, 110) }}</h2>
          <h2 v-else>{{ article.title }}</h2>
          <p v-if="isDesktop">{{ sanitize(article.smallDescription) }}</p>
        </div>
        <div class="card-article-horizontal-item-right-bottom">
          <ButtonRead
            :btn-link="{
              label: 'lire la suite',
              url: `${routes.blogHomeUrl}/articles/${article.slug}`,
            }"
            type="primary"
          />
        </div>
      </div>
    </NuxtLink>
  </li>
</template>
<style lang="scss" scoped>
.card-article-horizontal-item {
  @include box-shadow();
  width: 100%;
  margin-bottom: var(--showcase-size-100);
  &:last-child {
    margin-bottom: 0;
  }
  a {
    display: flex;
    text-decoration: none;
    color: $grey;
    &:hover {
      h2 {
        color: $primary-color;
      }
      .card-article-horizontal-item-left {
        img {
          transform: scale(1.1);
          transition: all ease-in-out 0.2s;
        }
      }
    }
  }
  &-left {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    min-width: 120px;
    max-width: 120px;
    @include mq(desktop) {
      min-width: 220px;
      max-width: 220px;
    }
    img {
      object-fit: cover;
      aspect-ratio: 1/1;
      width: fit-content;
      display: none;
      @include mq(desktop) {
        display: flex;
        transition: all ease-in-out 0.2s;
        height: 100%;
      }
    }
    &-infos {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      padding-left: var(--showcase-size-100);
      &--date {
        text-align: center;
        font-size: 11px;
        display: inline-block;
        margin-top: $gutter;
      }
    }
  }
  &-right {
    padding: var(--showcase-size-100);
    @include mq(desktop) {
      padding: var(--showcase-size-100) calc($gutter * 4);
    }
    &-top {
      display: none;
      @include mq(desktop) {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        &--date {
          font-weight: 800;
        }
        span {
          font-size: 14px;
          display: flex;
          align-items: center;
          margin-right: 5px;
          span {
            display: inline-block;
            margin-right: 2px;
          }
          i {
            margin-right: 5px;
            &:before {
              color: $grey;
            }
          }
        }
      }
    }
    &-middle {
      h2 {
        font-size: 16px;
        line-height: 22px;
        font-weight: 800;
        @include mq(desktop) {
          font-size: 20px;
        }
      }
      p {
        margin-bottom: $gutter;
        min-height: 46px;
      }
    }
    &-bottom {
      display: none;
      @include mq(desktop) {
        display: flex;
      }
    }
  }
}
</style>
